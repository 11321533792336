























import Vue from "vue";
import http from "../../utils/axios";
export default Vue.extend({
  data() {
    return {
      isOpacity: false,
      files: [],
      previews: [],
      uploadedImageLink: [] as string[]
    };
  },
  methods: {
    loadFiles(files: unknown[]) {
      this.previews = [];
      files.forEach((file: any) => {
        //@ts-ignore
        this.previews.push(URL.createObjectURL(file));

        this.imageHandler(file);

        this.isOpacity = false;
      });
    },

    imageHandler(file: string | Blob) {
      const token = localStorage.getItem("token");
      console.log(token);
      this.uploadedImageLink = [];
      const formData = new FormData();
      formData.append("image", file);

      return new Promise((resolve, reject) => {
        http
          .post("/image", formData, {
            headers: {
              token: token,
              "Content-type": "multipart/form-data"
            }
          })
          .then(response => {
            if (response) {
              resolve(this.uploadedImageLink.push(response.data.image));
              this.$emit("imageLinks", this.uploadedImageLink);
            }
          })
          .catch(error => reject(error));
      });
    },
    emitImageLinks() {
      this.$emit("imageLinks", this.uploadedImageLink);
    },
    previewFiles() {
      this.loadFiles(this.files);
    },
    removeImage(i: number) {
      this.previews.splice(i, 1);
    }
  }
});
