






















































































































import Vue from "vue";
import LocationChooser from "@/components/desktop/LocationChooser.vue";
import FileInput from "@/components/desktop/FileInput.vue";
import http from "../utils/axios";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default Vue.extend({
  name: "adpost-form",
  components: {
    "location-chooser": LocationChooser,
    "file-input": FileInput
  },
  mixins: [validationMixin],
  data() {
    return {
      submitSuccess: false,
      submitError: false,
      types: [
        { value: null, text: "Choose..." },
        { value: "sale", text: "Sale" },
        { value: "buy", text: "Buy" }
      ],
      form: {
        title: "",
        price: 0,
        desc: "",
        type: ""
      },
      itemLocation: {
        latitude: 0,
        longitude: 0,
        name: "",
        type: "Point"
      },
      imageLinks: [] as string[]
    };
  },
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(3)
      },
      qty: {
        required
      },
      price: {
        required
      },
      desc: {
        required
      },
      type: {
        required
      }
    }
  },

  methods: {
    validateState(value: unknown) {
      //@ts-ignore
      const { $dirty, $error } = this.$v.form[value];
      return $dirty ? !$error : null;
    },

    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const user = this.$store.getters.loggedInUser;
      const item = {
        title: this.form.title,
        description: this.form.desc,
        price: this.form.price,
        user: user._id,
        location: {
          name: this.itemLocation.name,
          coordinates: [
            this.itemLocation.longitude,
            this.itemLocation.latitude
          ],
          type: this.itemLocation.type
        },
        images: this.imageLinks,
        type: this.form.type
      };

      http
        .post("/items", item)
        .then(response => {
          console.log(response.data.item);
          this.submitSuccess = true;
        })
        .catch(error => {
          console.log(error);
          this.submitError = true;
        });

      //  alert("Form submitted!");
    },

    getImageLinks(imgUrl: string[]) {
      this.imageLinks = imgUrl;
    },

    getCurrLatLng(location: any) {
      this.itemLocation.latitude = location.position.lat;
      this.itemLocation.longitude = location.position.lng;
      this.itemLocation.name = location.address.label;
    }
  }
});
